<template>
  <div
    :class="{ 'bg-[#F0FBFB]': props.pageName === 'checkout' }"
    class="w-full mt-0 flex items-center py-5 print:hidden" 
  >
    <div
      class="mx-auto md:max-w-5xl w-full flex items-center justify-between px-4"
    >
      <div
        v-for="item in items"
        :key="item.title"
        class="flex items-center justify-around"
      >
        <NuxtImg
          loading="lazy"
          :src="item.icon"
          :alt="item.alt"
          class="h-7 w-7 md:h-8 md:w-8 mr-2 md:mr-5"
        />
        <div v-html="item.title" />
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  pageName: {
    type: String,
    required: false,
  },
});

const route = useRoute();
const path = route.path.split("/").splice(2, 1);
const { t } = useI18n();

const items = ref([
  {
    icon: "/icons/microscope.svg",
    alt: "microscope",
    title: `<div class='text-dark-blue md:font-semibold text-sm md:text-base'>
              <span class='hidden md:flex'>` + t("homePage.usp.item1") + `</span>
              <span class='flex md:hidden text-xs'>` + t("homePage.usp.mobile.item1") + `</span>
            </div>`,
  },
  {
    icon: "/icons/privacy.svg",
    alt: "security",
    title: `<div class='text-dark-blue md:font-semibold text-sm md:text-base'>
              <span class='hidden md:flex'>` + t("homePage.usp.item2") + `</span>
              <span class='flex md:hidden text-xs'>` + t("homePage.usp.mobile.item2") + `</span>
            </div>`,
  },
  {
    icon: "/icons/users-wm.svg",
    alt: "care",
    title: `<div class='text-dark-blue md:font-semibold text-sm md:text-base'>
              <span class='hidden md:flex'>` + t("homePage.usp.item3") + `</span>
              <span class='flex md:hidden text-xs'>` + t("homePage.usp.mobile.item3") + `</span>
            </div>`,
  },
]);
</script>
